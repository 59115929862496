import {DiffFileHeader} from '@github-ui/diff-file-header'
import {memo, useEffect, useMemo, useState} from 'react'
import type {DiffEntry, DiffUser, FileDiffReference} from '../types'
import type {CommentingImplementation, MarkerNavigationImplementation} from '@github-ui/conversations'
import {RichDiff} from './RichDiff'
import {DiffLines} from './DiffLines'
import styles from './Diff.module.css'
import {SubmoduleDiff} from './SubmoduleDiff'
import {useCopilotChatReference} from '../hooks/use-copilot-chat-reference'
import type {Repository} from '@github-ui/current-repository'

/**
 * DiffProps extends the Diff data type by adding additional,
 * more UI-related properties.
 */
export interface DiffProps extends DiffEntry {
  focusedSearchResult?: number
  collapsed: boolean
  diffManuallyExpanded: boolean
  commentingImplementation?: CommentingImplementation | undefined
  initialExpandedThreadId?: string
  markerNavigationImplementation?: MarkerNavigationImplementation | undefined
  rightSideContent: React.ReactNode | null
  leftSideContent: React.ReactNode | null
  onOptionCollapseToggle: (collapsed: boolean) => void
  currentUser: DiffUser
  repository: Pick<Repository, 'id' | 'name' | 'ownerLogin'>
  contextLinesURL: string
  basePath: string
}

export interface DiffContextProps extends DiffProps {
  canExpandOrCollapseLines: boolean
  hasExpandedAllRanges: boolean
  addInjectedContextLines: (range: {start: number; end: number}) => void
  expandAllContextLines: () => void
}

/**
 * Represents a single file diff.
 *
 * @params DiffContextProps
 * @returns Diff
 */

export const Diff = memo(DiffUnmemoized)

function DiffUnmemoized({
  collapsed,
  commentingEnabled,
  commentingImplementation,
  currentUser,
  diffContext,
  diffLines,
  diffManuallyExpanded,
  diffMatches,
  diffSize,
  focusedSearchResult,
  helpUrl,
  isBinary,
  isSubmodule,
  isTooBig,
  leftSideContent,
  linesAdded,
  linesChanged,
  linesDeleted,
  initialExpandedThreadId,
  markerNavigationImplementation,
  newTreeEntry,
  newCommitOid,
  objectId,
  oldTreeEntry,
  oldCommitOid,
  onOptionCollapseToggle,
  path,
  pathDigest,
  pullRequest,
  repository,
  richDiff,
  rightSideContent,
  hasExpandedAllRanges,
  expandAllContextLines,
  canExpandOrCollapseLines,
  status,
  submodule,
  truncatedReason,
  addInjectedContextLines,
}: DiffContextProps) {
  const [showRichDiff, setShowRichDiff] = useState(richDiff?.defaultToRichDiff)
  const showSubmodule = isSubmodule && !!submodule
  const showDiffLines = !showSubmodule && !showRichDiff
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const [linesManuallyUnhidden] = useState(diffManuallyExpanded)

  const copilotChatReference: FileDiffReference | undefined = useCopilotChatReference({
    isBinary,
    isSubmodule,
    path,
    status,
    repository,
    newCommitOid,
    newTreeEntry,
    oldCommitOid,
    oldTreeEntry,
    pathDigest,
    hasCopilotAccess: currentUser.hasCopilotAccess,
  })

  const toggleCollapse = (event: React.MouseEvent) => {
    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
    if (event.altKey) {
      onOptionCollapseToggle(!isCollapsed)
    } else {
      collapsed = !isCollapsed
      setIsCollapsed(!isCollapsed)
    }
  }

  useEffect(() => {
    setIsCollapsed(collapsed)
  }, [collapsed])

  const onHandleLoadDiff = async () => {}

  const viewerData = useMemo(() => {
    return {
      avatarUrl: currentUser?.avatarURL ?? '',
      diffViewPreference: currentUser?.splitPreference,
      isSiteAdmin: false,
      login: currentUser?.login ?? '',
      lineSpacingPreference: currentUser?.lineSpacing,
      tabSizePreference: currentUser?.tabSize ?? 8,
      viewerCanComment: currentUser?.canComment,
      viewerCanApplySuggestion: false,
      commentsPreference: currentUser?.commentsPreference,
    }
  }, [currentUser])

  return (
    <div className="position-relative" style={{contain: 'layout'}} key={`${pathDigest}_${diffLines.length}`}>
      <div className={styles.diffHeaderWrapper}>
        <DiffFileHeader
          areLinesExpanded={hasExpandedAllRanges}
          canExpandOrCollapseLines={canExpandOrCollapseLines}
          defaultToRichDiff={richDiff?.defaultToRichDiff}
          fileLinkHref={`#diff-${pathDigest}`}
          isCollapsed={isCollapsed}
          isBinary={isBinary}
          size={diffSize}
          canToggleRichDiff={richDiff?.canToggleRichDiff}
          linesAdded={linesAdded}
          linesChanged={linesChanged}
          linesDeleted={linesDeleted}
          newMode={newTreeEntry?.mode}
          newPath={newTreeEntry?.path}
          oldMode={oldTreeEntry?.mode}
          oldPath={oldTreeEntry?.path}
          patchStatus={status}
          path={path}
          onToggleExpandAllLines={expandAllContextLines}
          onToggleFileCollapsed={toggleCollapse}
          onToggleDiffDisplay={rich => setShowRichDiff(rich)}
          additionalLeftSideContent={leftSideContent}
          rightSideContent={rightSideContent}
        />
      </div>
      {!isCollapsed ? (
        <div className="border position-relative rounded-bottom-2">
          {showSubmodule && <SubmoduleDiff submodule={submodule} />}

          {showRichDiff && (
            <RichDiff
              loading={false}
              proseDiffHtml={richDiff?.proseDiffHtml}
              fileRendererInfo={richDiff?.renderInfo}
              dependencyDiffPath={richDiff?.dependencyDiffPath}
            />
          )}

          {showDiffLines && (
            <DiffLines
              diffContext={diffContext}
              copilotChatReference={copilotChatReference}
              searchResults={diffMatches}
              focusedSearchResult={focusedSearchResult}
              diffEntryData={{
                diffLines,
                isBinary,
                isTooBig,
                linesChanged,
                newTreeEntry,
                newCommitOid,
                objectId,
                oldTreeEntry,
                oldCommitOid,
                path,
                pathDigest,
                status,
                truncatedReason,
              }}
              baseHelpUrl={helpUrl}
              commentBatchPending={false}
              repositoryId={repository.id.toString()}
              subject={pullRequest?.subject || {}}
              subjectId={pullRequest?.globalRelayId || ''}
              viewerData={viewerData}
              newCommitOid={newCommitOid}
              oldCommitOid={oldCommitOid}
              addInjectedContextLines={addInjectedContextLines}
              commentingEnabled={commentingEnabled || false}
              commentingImplementation={commentingImplementation}
              initialExpandedThreadId={initialExpandedThreadId}
              markerNavigationImplementation={markerNavigationImplementation}
              diffLinesManuallyUnhidden={linesManuallyUnhidden}
              onHandleLoadDiff={onHandleLoadDiff}
            />
          )}
        </div>
      ) : null}
    </div>
  )
}

try{ Diff.displayName ||= 'Diff' } catch {}
try{ DiffUnmemoized.displayName ||= 'DiffUnmemoized' } catch {}